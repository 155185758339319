<template>
  <div class="join">
    <NavBar />
    <div class="banner">
      <p class="banner-title">加入我们</p>
    </div>
    <div class="company">
      <div class="company-content">
        <div class="company-title">公司介绍</div>
        <div class="company-intro">
          江苏众享金联科技有限公司成立于2016年，是一家基于区块链技术与物联网、大数据、人工智能、隐私计算等前沿科技的整体解决方案提供商，致力于为金融机构、政府及企业客户加速实现数字化转型提供全方位支持与服务的国家高新技术企业。目前已积累多项软著和专利。国内最早一批Hyperledger成员，全球首批五家Hyperledger认证服务提供商之一；工信部区块链产业白皮书参编单位；安永复旦2019最具潜力种子企业。多项案例入选福布斯2018中国TOP50、2020年中国产业区块链创新奖-“优秀案例”、2020年度区块链影响力品牌、中国通信学会“2020区块链技术与应用创新成果”奖项；中国信通院“2021十大金融高价值优秀案例”以及“2022中国产业区块链十大杰出案例”等；产学研方面先后与南京大学成立区块链联合实验室，与东南大学成立区块链联合创新中心。<br/>
          成立至今一直专注区块链底层技术的研发和产业应用落地，并相继发布国产自主可控的区块链数据库应用平台ChainSQL和企业级区块链平台
          Golden-Chain为底层基础设施，以及超轻量化零知识证明的数据隐私护航体系StealthHat、区块链智能部署服务平台WisChain、区块链可视化应用平台ChainViewer等标准化产品，综合各行业整体解决方案形成了业内独特区块链全生态技术体系，先后为国内多个领域的首个区块链项目提供技术支持与服务，涵盖：金融、政务、海关、民生、农业、供应链、档案、仓储物流、中医药、双碳等诸多关键领域，已支撑业务规模超万亿人民币。
        </div>
      </div>
    </div>
    <div class="felware">
      <div class="felware-title">福利待遇</div>
      <div class="felware-intro">
        <div class="left">
          <div class="left-desc">
            世界那么大，却又这么小，遇见众享金联，开启别样人生，这里有高手，听说与高手为伍能成长的更快，这里有伯乐等你，成就手握未来，心怀宇宙的你！
          </div>
          <h4>【我们提供】：</h4>
          <div class="provide">
            <div class="provide-item">
              <img src="@/assets/img/join/provide-1.png" />
              <span>五险一金</span>
            </div>
            <div class="provide-item">
              <img src="@/assets/img/join/provide-2.png" />
              <span>弹性工作</span>
            </div>
          </div>
          <div class="provide">
            <div class="provide-item">
              <img src="@/assets/img/join/provide-3.png" />
              <span>出国旅游</span>
            </div>
            <div class="provide-item">
              <img src="@/assets/img/join/provide-4.png" />
              <span>期权派发</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-item">
            <img src="@/assets/img/join/felware-1.png" />
            <p>各类假期</p>
          </div>
          <div class="right-item">
            <img src="@/assets/img/join/felware-2.png" />
            <p>弹性工作时间</p>
          </div>
          <div class="right-item">
            <img src="@/assets/img/join/felware-3.png" />
            <p>定期团建</p>
          </div>
          <div class="right-item">
            <img src="@/assets/img/join/felware-4.png" />
            <p>完善保障体系</p>
          </div>
          <div class="right-item">
            <img src="@/assets/img/join/felware-5.png" />
            <p>一对一指导</p>
          </div>
          <div class="right-item">
            <img src="@/assets/img/join/felware-6.png" />
            <p>行业领先</p>
          </div>
        </div>
      </div>
    </div>
    <div class="job">
      <div class="job-title">开启新的工作</div>
      <div class="job-content">
        <div class="job-nav">
          <div class="nav-title">筛选</div>
          <div class="nav-list">
            <div
              class="nav-item"
              v-for="(item, index) in job"
              :key="index"
              @click="tabIndex = index"
              :class="{ 'active-nav-item': index === tabIndex }"
            >
              {{ item.tab }}
            </div>
          </div>
        </div>
        <div class="job-list">
          <div class="list-title">职位</div>
          <div class="list-detail" v-if="tabIndex === null">
            <div
              class="list-detail-box"
              v-for="(jobs, index) in job"
              :key="index"
            >
              <div
                class="list-item"
                v-for="(item, index) in jobs.list"
                :key="index"
                @click="handleJobClick(index, jobs.tab)"
              >
                <div class="item-title">{{ item.title }}</div>
                <div class="item-tab">
                  <span
                    class="tab"
                    v-for="(tabs, index) in item.tab"
                    :key="index"
                    >{{ tabs }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="list-detail" v-else>
            <div
              class="list-item"
              v-for="(item, index) in job[tabIndex].list"
              :key="index"
              @click="handleJobClick(index)"
            >
              <div class="item-title">{{ item.title }}</div>
              <div class="item-tab">
                <span
                  class="tab"
                  v-for="(tabs, index) in item.tab"
                  :key="index"
                  >{{ tabs }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="job-footer">
        申请职位请发送简历至hr@peerfintech.cn<br />公司地址：江苏省南京市鼓楼区清江南路18号鼓楼创新广场B座10楼
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import ScrollReveal from "scrollreveal";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      job: [
        {
          tab: "校招",
          list: [
            {
              title: "区块链研发实习生",
              tab: ["南京", "研发部-技术产品组", "日薪110-160元/天"],
              content: [
                {
                  title: "职责描述",
                  text: "1、学习区块链相关知识； <br />2、参与区块链应用系统的设计与开发工作； <br />3、参与系统测试工作。",
                },
                {
                  title: "任职要求",
                  text: "1、	统招本科及以上学历，计算机相关专业；<br />2、熟悉常见数据结构与算法；<br />3、	熟练掌握c/c++、go、python、java中的至少一种； <br/>4、	满足正常出勤，至少实习半年；<br />5、	有实习经验者优先。",
                },
              ],
            },
            {
              title: "管培生",
              tab: ["南京", "技术岗/产品岗/综合岗"],
              content: [
                {
                  title: "技术岗（10k-20k）",
                  text: "具备扎实的技术理论知识，有一定的技术专长，对某一类技术有较深入的了解和实践应用；有较强的全局意识、解次问题能力以及项目推动意识，对区块链技术有浓厚的兴趣。",
                },
                {
                  title: "产品岗（8k-15k）",
                  text: "对互联网产品有极大热情，对产品设计过程的各种概念和知识有一定储备，对新鲜事物充满好奇，具备敏锐的市场洞察力，善于把握产品背后的商业价值；熟悉国内互联网发展历程，对各行业的发展模式有较多的了解并有自己的观点，对区块链技术有浓厚的兴趣。",
                },
                {
                  title: "综合岗（6k-12k）",
                  text: "有志在人力行政/市场/职能等岗位中长期发展并拥有相应的专业知识储备；具备较好的分析能力、解决问题能力以及合作服务意识、充分考虑他人，为企业创造价值。",
                },
              ],
            },
          ],
        },
        {
          tab: "社招",
          list: [
            {
              title: "售前工程师",
              tab: ["南京", "产品部", "11k-15k", "经验3-5年", "本科及以上"],
              content: [
                {
                  title: "职责描述",
                  text: "1、参与公司知识库建设及管理，各类文档、方案的标准化工作；<br />2、参与重点行业及客户对区块链产品的需求调研、产品规划与设计工作研究工作，挖掘需求并形成解决方案；<br/>3、负责完成与外部合作机构的对接工作，包括：合作洽谈，尽职调查，产品设计，业务流程梳理，具体合作方案的制定，并编写需求文档等相关工作 ；<br/>4、配合编写PPT、WORD阐述产品；<br/>5、参与客户交流、客户关系维护等工作。",
                },
                {
                  title: "任职要求",
                  text: "1、 计算机、互联网、电子、电气相关专业，本科及以上学历，了解区块链技术优先；<br/>2、 有投标文件、技术文档、解决方案、咨询方案编写工作经验者优先；<br/>3、 熟练掌握文档编辑、绘图工具，熟悉axure优先；<br/>4、 优秀的学习、归纳总结、文字组织驾驭、逻辑分析能力；<br/>5、 良好的沟通协调能力及团队合作能力，工作态度认真，严谨。",
                },
              ],
            },
            {
              title: "项目方案工程师",
              tab: ["南京", "产品部", "8k-10k", "经验1-3年", "本科及以上"],
              content: [
                {
                  title: "职责描述",
                  text: "1、配合业务部门完成项目招投标工作，包括技术方案的编制、技术标书应答、项目报价等相关工作;<br/>2、负责公司资质材料和招投标文件的维护、更新和归档管理等工作;<br/>3、负责公司相关课题申报、公司技术产品及研发成果的总结整理，并按规定如期完成课题内容制作、文件盖章投递等工作;<br/>4、负责公司方案库的维护、整理，能够熟悉公司技术路线及相关的产品、解决方案、历史项目案例情况。",
                },
                {
                  title: "任职要求",
                  text: "1、工作严谨，做事稳重，能够严守工作秘密，有一-定的文字功底、熟练掌握办公软件、语言表达能力和理解能力强;<br/>2、了解并热爱区块链行业，能够主动学习相关行业知识并学会归纳总结项目经验;<br/>3、本科及以上学历，计算机相关专业毕业，熟悉标书文件制作规范，熟悉项目招投标方面的工作流程，--年及以上招投标或项目申报工作经验;<br/>4、具有区块链、金融、政务、教育、智慧城市、乡村振兴、物联网、软件集成等行业标书工作经验，并能独立完成招投标者优先;<br/>5、能适应短期出差和加班。",
                },
              ],
            },
            {
              title: "Java研发工程师",
              tab: [
                "南京",
                "研发部-技术产品组",
                "10k-15k（12个月）",
                "经验1-3年",
                "本科及以上",
              ],
              content: [
                {
                  title: "任职要求",
                  text: "1. 统招本科及以上学历，计算机相关专业毕业优先，1年以上后端Java开发经验；<br/>2. 熟练掌握Java技能，熟悉Java常用库、Java项目构建、工程化机制及工具使用；<br/>3. 熟练使用springboot工程项目构建，注解，拦截器等使用；<br/>4. 熟练掌握Linux常用命令的使用及参数配置，熟悉shell脚本语法；<br/>5. 熟悉常用的数据结构及算法，熟悉软件系统设计方法及理论，能独立完成系统模块开发；<br/>6. 熟悉SQL和NoSQL的一种或多种，熟悉数据库设计的基本方法及理论；<br/>7. 熟悉Git、MarkDown、JIRA、Docker、CI/CD产品研发管理工具及流程；<br/>8. 具有良好的优化代码和代码层次结构划分能力，清晰的代码架构思想；<br/>9. 具有规范的代码编码习惯，严谨的软件工程项目的规范、流程和意识；<br/>10. 热衷技术的研究和创新，具有很强的学习能力和技术敏感度，抗压能力强，有正确的价值观。",
                },
              ],
            },
            {
              title: "前端工程师",
              tab: [
                "南京",
                "研发部-技术产品组",
                "9k-14k",
                "经验1-3年",
                "本科及以上",
              ],
              content: [
                {
                  title: "职责描述",
                  text: "1、与设计师协作，根据设计完成Web、h5页面制作；配合后台开发人员实现产品交互界面；<br/>2、针对UI设计图能够进行切图以及简单的图片处理，编写CSS规范，并形成CSS基础框架，精通跨浏览器的Web前端开发；<br/>3、负责网站、h5及交互方面的开发维护；<br/>4、负责改进产品的用户体验，优化前端代码；<br/>5、能够理解后端架构，与后端工程师配合，为项目提供最优化的技术解决方案；和UI设计并进行分析，优化前端用户操作体验。",
                },
                {
                  title: "任职要求",
                  text: "1、本科以上学历，计算机相关专业优先，1年以上前端开发工作经验；<br/>2、熟悉react或vue技术栈，熟悉常用前端技术框架，熟悉HTML5、JavaScript、CSS、Ajax、JQuery等语言和技术；<br/>3、熟悉微信小程序开发部署；<br/>4、具有良好的编程习惯和较强的的学习能力；具有较高的积极性、良好的心态和沟通能力；<br/>5、有浏览器兼容性开发和调试经验，熟练使用各种浏览器调试工具。",
                },
              ],
            },
          ],
        },
      ],
      tabIndex: null,
    };
  },
  methods: {
    handleJobClick(jobIndex, tabIndex) {
      if (tabIndex === "校招") {
        let routeData = this.$router.resolve({
          path: "/join-us/detail",
          query: {
            tabIndex: 0,
            jobIndex: jobIndex,
          },
        });
        window.open(routeData.href, "_blank");
      } else if (tabIndex === "社招") {
        let routeData = this.$router.resolve({
          path: "/join-us/detail",
          query: {
            tabIndex: 1,
            jobIndex: jobIndex,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          path: "/join-us/detail",
          query: {
            tabIndex: this.tabIndex,
            jobIndex: jobIndex,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //滚动动画
    ScrollReveal().reveal(document.querySelector(".company-content"), {
      duration: 1000,
      distance: "100px",
      origin: "bottom",
      reset: true,
    });
    ScrollReveal().reveal(document.querySelectorAll(".right-item"), {
      duration: 1000,
      distance: "100px",
      origin: "bottom",
      reset: true,
    });
    ScrollReveal().reveal(document.querySelector(".job-content"), {
      duration: 1000,
      distance: "100px",
      origin: "bottom",
      reset: true,
    });
  },
};
</script>
<style scoped lang="scss">
.join {
  .content-width {
   width: 1200px;
    margin: 0px auto;
  }
  .banner {
    margin-top: 64px;
    width: 100%;
    height: 476px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/join/bg.png") center/cover no-repeat;
    .banner-title {
      @extend .content-width;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      color: #000000;
      line-height: 476px;
    }
  }

  .commom-title {
    font-family: "PingFangSC-Medium";
    font-size: 32px;
    color: #000000;
    line-height: 45px;
    padding: 70px 0px 50px;
    text-align: center;
  }
  .company {
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/join/company.png") center/cover no-repeat;
    line-height: 747px;
    padding: 50px 0px;
    .company-content {
      @extend .content-width;
      background: #ffffff;
      .company-title {
        @extend .commom-title;
      }
      .company-intro {
        padding: 0px 120px 50px;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
      }
    }
  }
  .felware {
    @extend .content-width;
    .felware-title {
      @extend .commom-title;
    }
    .felware-intro {
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;
      .left {
        width: 600px;
        .left-desc {
          padding: 50px 0px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 26px;
        }
        h4 {
          font-size: 17px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          margin-bottom: 32px;
        }
        .provide {
          display: flex;
          margin-bottom: 24px;
          .provide-item {
            img {
              width: 26px;
              height: 26px;
              vertical-align: middle;
              margin-right: 12px;
            }
            span {
              font-size: 17px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 26px;
              margin-right: 100px;
            }
          }
        }
      }
      .right {
        width: 522px;
        height: 336px;
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;
        .right-item {
          width: 150px;
          height: 150px;
          text-align: center;
          background: #ffffff;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
          img {
            width: 61px;
            height: 61px;
            margin: 22px 0px 20px;
          }
          p {
            font-size: 18px;
            font-family: "PingFangSC-Medium";
            color: #000000;
            line-height: 26px;
          }
        }
      }
    }
  }
  .job {
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/join/job-bg.png") center/cover no-repeat;
    .job-title {
      @extend .commom-title;
      padding-top:50px;
    }
    .job-content {
      @extend .content-width;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      height: 557px;
      .job-nav {
        width: 260px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-right: 24px;
        padding: 24px 12px;
        .nav-title {
          font-size: 24px;
          font-family: "PingFangSC-Medium";
          color: #000000;
          line-height: 33px;
          margin-bottom: 20px;
          padding-left: 20px;
        }
        .nav-item {
          height: 38px;
          border-radius: 4px;
          margin-bottom: 4px;
          padding-left: 20px;
          font-size: 16px;
          color: #000000;
          line-height: 38px;
          cursor: pointer;
          &:hover {
            background: #0077ff;
            color: #ffffff;
            transition: all 0.3s;
          }
        }
        .active-nav-item {
          background: #0077ff;
          color: #ffffff;
        }
      }
      .job-list {
        flex: 1;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 24px 32px 16px;
        .list-title {
          font-size: 24px;
          font-family: "PingFangSC-Medium";
          color: #000000;
          line-height: 33px;
          margin-bottom: 11px;
        }
        .list-detail {
          height: 473px;
          overflow: auto;
          .list-item {
            cursor: pointer;
            padding: 15px 0px;
            border-bottom: 1px solid #efefef;
            .item-title {
              font-size: 16px;
              font-family: "PingFangSC-Medium";
              color: #000000;
              line-height: 22px;
              margin-bottom: 8px;
            }
            .item-tab {
              height: 17px;
              .tab {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 17px;
                padding: 0px 10px;
                border-right: 1px solid #d8d8d8;
                &:first-child {
                  padding-left: 0px;
                }
                &:last-child {
                  padding-right: 0px;
                  border-right: none;
                }
              }
            }
            &:hover {
              background: #f5f5f5;
              .item-title {
                color: #0077ff;
              }
            }
          }
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.2);
          }
          &::-webkit-scrollbar-track {
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .job-footer {
      @extend .content-width;
      border-top: 1px solid #dbdbdb;
      padding: 23px 0px 70px;
      text-align: center;
      font-size: 14px;
      color: #000000;
      line-height: 26px;
    }
  }
}
</style>
